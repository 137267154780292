export const environment = {
  
  
  production: true,
  config: {
    apiKey: "AIzaSyDOtr7uQGsF0itIDR7XoqgDAWnfTa_LMtI",
    authDomain: "ixadinscription.firebaseapp.com",
    databaseURL: "https://ixadinscription.firebaseio.com",
    projectId: "ixadinscription",
    storageBucket: "ixadinscription.appspot.com",
    messagingSenderId: "874637400983",
    appId: "1:874637400983:web:b63e5e7045575d429842d2"
  },
  setDateEtape1ForUserUrl: "https://inscription.ixad.fr/IxadInscription/data/etape1/user/",
  setDateEtape2ForUserUrl: "https://inscription.ixad.fr/IxadInscription/data/etape2/user/",
  setDateEtape3ForUserUrl: "https://inscription.ixad.fr/IxadInscription/data/etape3/user/",
  setDateEtape4ForUserUrl: "https://inscription.ixad.fr/IxadInscription/data/etape4/user/",
  setDateEtape5ForUserUrl: "https://inscription.ixad.fr/IxadInscription/data/etape5/user/",
  setDateEtape6ForUserUrl: "https://inscription.ixad.fr/IxadInscription/data/etape6/user/",
  setFinaliseInscription:  "https://inscription.ixad.fr/IxadInscription/data/finaliseinscription/user/",
  getDateEtape1ForUserUrl: "https://inscription.ixad.fr/IxadInscription/data/etape1/user/",
  getDateEtape2ForUserUrl: "https://inscription.ixad.fr/IxadInscription/data/etape2/user/",
  getDateEtape3ForUserUrl: "https://inscription.ixad.fr/IxadInscription/data/etape3/user/",
  getDateEtape4ForUserUrl: "https://inscription.ixad.fr/IxadInscription/data/etape4/user/",
  getDateEtape5ForUserUrl: "https://inscription.ixad.fr/IxadInscription/data/etape5/user/",
  getDateEtape6ForUserUrl: "https://inscription.ixad.fr/IxadInscription/data/etape6/user/",
  getIsDocteurDroitUrl: "https://inscription.ixad.fr/IxadInscription/data/docteurDroit/user/",
  getIsInscritUrl: "https://inscription.ixad.fr/IxadInscription/data/checkInscription/user/",
  getIsPreInscritUrl: "https://inscription.ixad.fr/IxadInscription/data/checkPreInscription/user/",
  getActiviteExtUrl: "https://inscription.ixad.fr/IxadInscription/data/checkActiviteExt/user/",
  getPreInscriptionValideIxad: "https://inscription.ixad.fr/IxadInscription/data/checkUserPreInscriptionValideIxad/user/",

  getLoadParamUrl: "https://inscription.ixad.fr/IxadInscription/public/data/params/",

  getReferentielAnneeUrl: "https://inscription.ixad.fr/IxadInscription/data/referentiel/annees/",
  getReferentielBacUrl: "https://inscription.ixad.fr/IxadInscription/data/referentiel/bac/",
  getReferentielLicenceUrl: "https://inscription.ixad.fr/IxadInscription/data/referentiel/licence/",
  getReferentielMasterUrl: "https://inscription.ixad.fr/IxadInscription/data/referentiel/master/",
  getReferentielPaysUrl: "https://inscription.ixad.fr/IxadInscription/data/referentiel/pays/",
  getReferentielVillesUrl: "https://inscription.ixad.fr/IxadInscription/data/referentiel/villes/",

  createPayRequest:"https://inscription.ixad.fr/IxadInscription/data/paiement/createPaiement",
  getCheckPaiemURL:"https://inscription.ixad.fr/IxadInscription/data/inscription/paiement/check/user/"



};
