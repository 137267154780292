import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {


  loginUser:string;
  passwordUser:string;
  errorMessage:string;

  constructor(public authService:  AuthService) {



  }




  ngOnInit(): void {

    

  }



  login(){

    const firebaseErrors = {
      'auth/user-not-found': 'Utilisateur non trouvé',
      'auth/email-already-exists': 'Adresse email déjà enregistrée',
      'auth/invalid-email':'Adresse email invalide',
      'auth/invalid-email-verified':'Email non vérifié, vérifiez vos emails',
      'auth/invalid-password':'Password non valide',
      'auth/too-many-requests':'Trop de tentatives d\'authentification répétées. Veuillez attendre quelques minutes',
      'auth/wrong-password':'Password non valide',
      'auth/argument-error':'Renseignez les champs',
      'OK':''
    }; 

    this.authService.login(this.loginUser, this.passwordUser).then(result =>{
      
      if(result != "OK"){

        try{
          const parsedError = JSON.parse(result.message); 
          if (parsedError && parsedError.error && parsedError.error.message === 'INVALID_LOGIN_CREDENTIALS') {
            this.errorMessage = 'Identifiants de connexion invalides. Veuillez vérifier votre email et votre mot de passe.';
          } else {
            if(result in firebaseErrors)
            {
              this.errorMessage = firebaseErrors[result.code];
            }
            else{
              this.errorMessage = 'Erreur : ' + (parsedError.error?.message || 'Un problème est survenu');
            }
          }
        } catch (e) {
          // Si JSON.parse échoue, retourner un message d'erreur générique
          if(result && result.code && result.code in firebaseErrors)
          {
            this.errorMessage = firebaseErrors[result.code];
          }
          else
          {
            this.errorMessage = "Erreur : un problème est survenu";
          }
          
        }
        
       
      }
      
    
    });
  


  }

}
